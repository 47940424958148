<template>
  <main class="form-signin text-center">
    <form @submit="handleSubmit" method="post">
      <img
        class="mb-4"
        src="https://externisti.akip.cz/assets/img/logo.png"
        alt=""
        width="300"
      />
      <h1 class="h3 mb-3 fw-normal">Změna hesla</h1>

      <div class="form-floating">
        <input
          type="password"
          class="form-control"
          v-model="password1"
          placeholder="Heslo"
          required
        />
      </div>
      <div class="form-floating">
        <input
          type="password"
          class="form-control"
          v-model="password2"
          placeholder="Zopakujte heslo"
          required
        />
      </div>
      <button class="w-100 btn btn-lg btn-success" type="submit" value="Submit">
        Změnit heslo
      </button>
    </form>
  </main>
</template>

<script>
const axios = require("axios");
export default {
  name: "HelloWorld",
  data() {
    return {
      password1: null,
      password2: null,
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();


      if(this.password1 != this.password2){
        this.$swal({icon: 'error', timer: 2500,showConfirmButton: false, title: "Hesla se musí shodovat"});
        return;
      }

       if(this.password1.length < 5){
        this.$swal({icon: 'error', timer: 2500,showConfirmButton: false, title: "Heslo musí obsahovat alespoň 6 znaků"});
        return;
      }

console.log(this.$route.params.token);

      axios
        .post("https://api.externisti.akip.cz/mapi/reset-password/reset/"+this.$route.params.token, {
          password: this.password1,
        })
        .then( result => {
          console.log(result);
          this.showAlert(200)
        })
        .catch( error => {
          console.log(error);
          this.showAlert(500)
        });
    },
    showAlert(type){

      if(type == 200){
        this.$swal({icon: 'success', timer: 3000, title: 'Vaše heslo bylo úspěšně změněno'});
      }else if(type == 500){
        this.$swal({icon: 'error', timer: 2500,showConfirmButton: false, title: "Při změně hesla se vyskytla chyba."});
      }

    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  text-transform: uppercase;
  color: #ffff;
  font-weight: 500;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

html,
body {
  height: 100%;
}

main {
  height: 100vh;
  display: flex;
  align-items: center;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
}
</style>
